/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface OptionalsReportDTO
 */
export interface OptionalsReportDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionalsReportDTO
     */
    name?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof OptionalsReportDTO
     */
    studentYear?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof OptionalsReportDTO
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptionalsReportDTO
     */
    series?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OptionalsReportDTO
     */
    averageGrade?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionalsReportDTO
     */
    optionsS1?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionalsReportDTO
     */
    repartitionS1?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionalsReportDTO
     */
    optionsS2?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionalsReportDTO
     */
    repartitionS2?: Array<string> | null;
}

export function OptionalsReportDTOFromJSON(json: any): OptionalsReportDTO {
    return OptionalsReportDTOFromJSONTyped(json, false);
}

export function OptionalsReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalsReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'studentYear': !exists(json, 'studentYear') ? undefined : StudentYearEnumFromJSON(json['studentYear']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'series': !exists(json, 'series') ? undefined : json['series'],
        'averageGrade': !exists(json, 'averageGrade') ? undefined : json['averageGrade'],
        'optionsS1': !exists(json, 'optionsS1') ? undefined : json['optionsS1'],
        'repartitionS1': !exists(json, 'repartitionS1') ? undefined : json['repartitionS1'],
        'optionsS2': !exists(json, 'optionsS2') ? undefined : json['optionsS2'],
        'repartitionS2': !exists(json, 'repartitionS2') ? undefined : json['repartitionS2'],
    };
}

export function OptionalsReportDTOToJSON(value?: OptionalsReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'studentYear': StudentYearEnumToJSON(value.studentYear),
        'domain': value.domain,
        'series': value.series,
        'averageGrade': value.averageGrade,
        'optionsS1': value.optionsS1,
        'repartitionS1': value.repartitionS1,
        'optionsS2': value.optionsS2,
        'repartitionS2': value.repartitionS2,
    };
}

