import { OptionalsReportsApi } from "../Api/apis";
import { ShortSemesterEnum } from "../Api/models";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new OptionalsReportsApi(getAuthConfiguration());

export const generateChangeRequestReport = (masters: boolean, onlyApproved: boolean) => {
    return ApiFactory().apiOptionalsReportsGenerateChangeRequestReportPost({ changeRequestReportRequestDTO: {masters, onlyApproved}})
}

export const generateChangeRequestStatisticsReport = (masters: boolean) => {
    return ApiFactory().apiOptionalsReportsGenerateChangeRequestsStatisticsReportPost({ optionalsReportsDTO: { masters } });
}

export const generateOptionalsReport = (masters: boolean) => {
    return ApiFactory().apiOptionalsReportsGenerateOptionalsReportPost({optionalsReportsDTO: {masters}})
}

export const generateOptionalsStatisticsReport = (masters: boolean, semester: ShortSemesterEnum) => {
    return ApiFactory().apiOptionalsReportsGenerateOptionalsStatisticsReportPost({optionalsStatisticsReportRequestDTO: {masters, semester} })
}