/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SemesterEnum,
    SemesterEnumFromJSON,
    SemesterEnumFromJSONTyped,
    SemesterEnumToJSON,
} from './SemesterEnum';
import {
    Specialization,
    SpecializationFromJSON,
    SpecializationFromJSONTyped,
    SpecializationToJSON,
} from './Specialization';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface Subject
 */
export interface Subject {
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Subject
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subject
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    domain?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof Subject
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {SemesterEnum}
     * @memberof Subject
     */
    semester?: SemesterEnum;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    initialAvailableSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    availableSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    availableSpotsFirstYear?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    availableSpotsSecondYear?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    minimumSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    maximumSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    enrolledStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    initialEnrolledStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    lastGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    subjectId?: number;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    specializationId?: string | null;
    /**
     * 
     * @type {Specialization}
     * @memberof Subject
     */
    specialization?: Specialization;
}

export function SubjectFromJSON(json: any): Subject {
    return SubjectFromJSONTyped(json, false);
}

export function SubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'semester': !exists(json, 'semester') ? undefined : SemesterEnumFromJSON(json['semester']),
        'initialAvailableSpots': !exists(json, 'initialAvailableSpots') ? undefined : json['initialAvailableSpots'],
        'availableSpots': !exists(json, 'availableSpots') ? undefined : json['availableSpots'],
        'availableSpotsFirstYear': !exists(json, 'availableSpotsFirstYear') ? undefined : json['availableSpotsFirstYear'],
        'availableSpotsSecondYear': !exists(json, 'availableSpotsSecondYear') ? undefined : json['availableSpotsSecondYear'],
        'minimumSpots': !exists(json, 'minimumSpots') ? undefined : json['minimumSpots'],
        'maximumSpots': !exists(json, 'maximumSpots') ? undefined : json['maximumSpots'],
        'enrolledStudents': !exists(json, 'enrolledStudents') ? undefined : json['enrolledStudents'],
        'initialEnrolledStudents': !exists(json, 'initialEnrolledStudents') ? undefined : json['initialEnrolledStudents'],
        'lastGrade': !exists(json, 'lastGrade') ? undefined : json['lastGrade'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'specializationId': !exists(json, 'specializationId') ? undefined : json['specializationId'],
        'specialization': !exists(json, 'specialization') ? undefined : SpecializationFromJSON(json['specialization']),
    };
}

export function SubjectToJSON(value?: Subject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'domain': value.domain,
        'year': StudentYearEnumToJSON(value.year),
        'semester': SemesterEnumToJSON(value.semester),
        'initialAvailableSpots': value.initialAvailableSpots,
        'availableSpots': value.availableSpots,
        'availableSpotsFirstYear': value.availableSpotsFirstYear,
        'availableSpotsSecondYear': value.availableSpotsSecondYear,
        'minimumSpots': value.minimumSpots,
        'maximumSpots': value.maximumSpots,
        'enrolledStudents': value.enrolledStudents,
        'initialEnrolledStudents': value.initialEnrolledStudents,
        'lastGrade': value.lastGrade,
        'subjectId': value.subjectId,
        'specializationId': value.specializationId,
        'specialization': SpecializationToJSON(value.specialization),
    };
}

